// Importing the global css file
import "@/assets/globals.css";
// Import necessary functionality from vue-router
import store from "@/store/index.js";
import { createRouter, createWebHistory } from "vue-router";
// Defining route components:
// import Homepage from "../views/Homepage.vue"
// import Profile from "../views/Profile.vue"
// import Login from "../views/Login.vue"

// Definition of the actual routes
const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Root",
    meta: {
      requiresAuth: true,
      header: true,
      footer: true,
      sidebar: true,
    },
  },
  {
    path: "/alt-layout",
    name: "AltLayout",
    //component: Altpage,
    component: () => import("@/views/Altpage.vue"),
    meta: {
      requiresAuth: true,
      header: false,
    },
  },
  {
    path: "/home",
    name: "Home",
    //component: Homepage,
    component: () => import("@/views/Homepage.vue"), // lazy loading
    meta: {
      requiresAuth: true,
      header: true,
      sidebar: true,
      footer: true,
    },
  },
  {
    path: "/view/:sharedid",
    name: "View",
    //component: Homepage,
    component: () => import("@/views/Homepage.vue"), // lazy loading
    meta: {
      requiresAuth: false,
      header: true,
      sidebar: false,
      footer: true,
    },
    beforeEnter: (to, from, next) => {
      //console.log("shared route beforeEnter");
      //console.log(`  -> to.params.sharedid: ${to.params.sharedid}`);
      store.commit("auth/loginAsGuest");
      store.commit("calc/setSharedId", to.params.sharedid);
      next();
    },
  },
  {
    path: "/profile",
    name: "Profile",
    // component: Profile,
    component: () => import("@/views/Profile.vue"), // lazy loading
    meta: {
      requiresAuth: true,
      header: true,
      sidebar: true,
    },
  },
  {
    path: "/calculation/:sharedid?",
    name: "Shared",
    // component: Login,
    component: () => import("@/views/Login.vue"), // lazy loading
    meta: {
      requiresAuth: false,
      header: false,
    },
    //when this route is called, put the sharedid in the store
    beforeEnter: (to, from, next) => {
      //console.log("shared route beforeEnter");
      // console.log(`  -> to.params.sharedid: ${to.params.sharedid}`);
      store.commit("calc/setSharedId", to.params.sharedid);
      next();
    },
  },

  {
    path: "/login/:rst?",
    name: "Login",
    // component: Login,
    component: () => import("@/views/Login.vue"), // lazy loading
    meta: {
      requiresAuth: false,
      header: false,
    },
  },
  {
    path: "/reset/:token",
    name: "Reset",
    // component: Login,
    component: () => import("@/views/Reset.vue"), // lazy loading
    meta: {
      requiresAuth: false,
      header: false,
    },
  },
  // fallback route that redirects to the homepage
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  // history implementation
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

// router is defined at eof
router.beforeEach(async (to, from, next) => {
  // console.log("Router-before-each:");
  // console.log(`  -> from.fullPath: ${from.fullPath}`);
  if (to.meta.requiresAuth) console.log("  ---> [to] requires authentication.");
  // Dispatch the "fetchaccesstoken" action
  // If not logged in, redirect to login
  const loggedIn = store.state.auth.loggedIn;
  //console.log(`  -> logged in? ${loggedIn}`);
  if (to.meta.requiresAuth && !loggedIn) {
    console.log(
      "  ---> [to] requires authentication, but user is not logged in."
    );
    // First, get query parameters. E.g., "referrer"
    router.push({
      path: "/login",
      query: to.query,
    });
  }
  // if logged in but trying to go to login, redirect to home/default page
  if (to.fullPath === "/login") {
    if (loggedIn) {
      router.push("/");
    }
  }
  if (to.fullPath === "/home") {
    try {
      await store.dispatch("auth/getPrefs");
    } catch (error) {
      console.error("Error getting logo: " + error);
    }
    if (store.state.calc.inputs.length === 0) {
      // If we have no layout yet, we shouldn't check if there is an alt_layout already. First
      // make sure we have a regular layout!
      await store.dispatch("calc/getProducts");
      await store.dispatch("calc/getInputs");
    }

    if (
      store.state.calc.alt_layout &&
      Object.keys(store.state.calc.alt_layout).length > 0 &&
      from.fullPath != "/alt-layout"
    ) {
      router.push("/alt-layout");
    }
  }
  if (from.fullPath == "/login") {
  }
  next();
});

export default router;
