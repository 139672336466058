import store from ".";
import { trySecureAxios } from "@/util/authutil.js";

export const metrics = {
  namespaced: true,
  state: {
    // we'll store metrics we already fetched. In a single session, this will
    // allow us to avoid fetching them again.
    own_requests: [],
    super_requests: [],
    error: "",
    loading: false,
  },
  actions: {
    async getMetrics({ commit, state }, obj) {
      state.loading = true;
      let endDate = new Date();
      let startDate = new Date(new Date().setDate(endDate.getDate() - 7));

      if (obj.startDate) {
        startDate = new Date(obj.startDate);
      }
      if (obj.endDate) {
        endDate = new Date(obj.endDate);
      }

      let body = {
        from: startDate.toISOString(),
        until: endDate.toISOString(),
      };

      if (obj.userId) {
        body.userId = obj.userId;
      }

      let res = await trySecureAxios("post", "rest/getMetrics/", body, 3);

      state.loading = false;

      if (res.status !== 200) {
        await commit("setError", {
          error: {
            nl: "Er ging iets mis bij het ophalen van de statistieken.",
            en: "Something went wrong while fetching the metrics.",
            fr: "Quelque chose s'est mal passé lors de la récupération des statistiques.",
            de: "Beim Abrufen der Metriken ist ein Fehler aufgetreten.",
          },
        });
        return;
      }

      await commit("setMetrics", {
        own_requests: res.data.own_requests,
        super_requests: res.data.super_requests,
      });

      return res.data;
    }, // End getMetrics
  },
  mutations: {
    setMetrics(state, { own_requests, super_requests }) {
      state.own_requests = own_requests;
      state.super_requests = super_requests;
    },
    setError(state, { error }) {
      state.error = error;
    },
  },
};
