// import {inputs, submit} from "@/inputs.js"
import store from "@/store/index";

/** getInputValues: Set calc_result in store. Also set calc_params, needed to generate PDF
 * @function getInputValues
 *
 * @returns "flat" object containing inputs w IDs as defined in input object
 */
function getInputValues() {
  if (!store) {
    return false;
  }
  let req_obj = {};
  const inputs = store.state.calc.inputs;
  const repeatInputs = store.state.calc.repeatInputs;
  const allProducts = store.state.calc.all_products;

  // we'll keep track of an array of chosen products, to send to the
  // API/server.
  const chosenProducts = [];

  const hiddenInputs = store.state.calc.hiddenInputs;
  if (hiddenInputs) {
    for (let input in hiddenInputs) {
      req_obj[hiddenInputs[input].inputId] = hiddenInputs[input].value;
    }
  }

  for (let i = 0; i < Object.keys(repeatInputs).length; ++i) {
    // Make sure these inputs are actually enabled. Just to make sure they don't get sent
    // when an eventual condition toggle is clicked.
    // Explicit false check to make this default to "true"
    let rep = Object.keys(repeatInputs)[i];
    if (repeatInputs[rep].enabled === false) {
      continue;
    }
    for (let ib in repeatInputs[rep].inputs) {
      let block = repeatInputs[rep].inputs[ib];
      for (let i in block) {
        let input = block[i];
        // If no array exists yet for a series:
        if (!req_obj[input.series]) req_obj[input.series] = [];
        if (input.use !== false) req_obj[input.series].push(input.value);
      }
    }
  }

  for (let page in inputs) {
    page = inputs[page];
    for (let column in page.columns) {
      column = page.columns[column];
      if (column.type === "COLUMN_INPUT") {
        for (let input_block in column.input_blocks) {
          input_block = column.input_blocks[input_block];
          let enabled = true;
          if (input_block.condition) {
            enabled = enabled && input_block.condition.value;
            // add condition inputId to req_obj
            req_obj[input_block.condition.inputId] =
              input_block.condition.value;
          }

          if (enabled) {
            // Check if using alternative (/"advanced") or regular inputs
            let real_in = input_block.inputs;

            // This should be "overwritten" because of product choices
            if (input_block.alt_inputs) {
              real_in = real_in.concat(input_block.alt_inputs);
            }
            for (let field in real_in) {
              let obj = real_in[field];
              if (obj.use === false) {
                continue;
              }
              if (
                obj.type == "productpicker" ||
                obj.type == "productpicker_dropdown"
              ) {
                // search inputproducts for the product with the name = obj.value
                let value = allProducts.find((product) => {
                  // NOTE we're comparing strings. If json-stringify's implementation
                  // would change or sth, this would break. JS does not have
                  // a native way to compare objects.
                  return (
                    JSON.stringify(product.parameters) ===
                    JSON.stringify(obj.value)
                  );
                });

                if (value !== undefined) {
                  chosenProducts.push(value);
                  for (let param in value.parameters) {
                    req_obj[param] = value.parameters[param];
                  }
                }
              } else {
                if (typeof obj.value === "object") {
                  if (obj.series) {
                    for (let series_key in obj.series) {
                      const series_obj = obj.series[series_key];
                      // If series_obj.length is a string, search for it in
                      // req_obj and get the length of that array, else
                      // use the length of the series_obj directly
                      let len =
                        (req_obj[series_obj.length] &&
                          req_obj[series_obj.length].length) ||
                        series_obj.length;
                      if (len === 1 && typeof req_obj.length === "number") {
                        req_obj[series_key] =
                          obj.value[series_obj.param] || obj.value;
                      } else {
                        req_obj[series_key] = [];
                        for (let i = 0; i < len; ++i) {
                          req_obj[series_key].push(
                            obj.value[series_obj.param] || obj.value
                          );
                        }
                      }
                    }
                  } // End if object.series
                  if (obj.flatten) {
                    for (let key in obj.value) {
                      req_obj[key] = obj.value[key];
                    }
                  } else if (obj.inputId) {
                    req_obj[obj.inputId] = obj.value;
                  }
                } else {
                  if (obj.series) {
                    for (let series_key in obj.series) {
                      const series_obj = obj.series[series_key];
                      // If series_obj.length is a string, search for it in
                      // req_obj and get the length of that array, else
                      // use the length of the series_obj directly
                      let len =
                        (req_obj[series_obj.length] &&
                          req_obj[series_obj.length].length) ||
                        series_obj.length;
                      if (len === 1 && typeof req_obj.length === "number") {
                        req_obj[series_key] = obj.value;
                      } else {
                        req_obj[series_key] = [];
                        for (let i = 0; i < len; ++i) {
                          req_obj[series_key].push(obj.value);
                        }
                      }
                    }
                  }
                  if (obj.inputId) req_obj[obj.inputId] = obj.value;
                } // End if type of value is not object
              } // End if object is not productpicker
            } // end for field in inputs[input].inputs
          } // End if (enabled)
        } // End for input block in input_blocks
      } // End if column type is INPUT
      else if (column.type === "COLUMN_SUBMIT") {
        for (let input in column.submit.inputs) {
          let obj = column.submit.inputs[input];
          if (obj) {
            req_obj[obj.inputId] = obj.value;
          } // End if obj
        } // End for input in column.submit.inputs
      } // End if column_type is SUBMIT
    } // End column in page.columns
  } // End page in inputs

  req_obj["chosen_products"] = chosenProducts;

  console.log("req_obj", req_obj);

  return req_obj;
}

export { getInputValues };
