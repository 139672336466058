import { createStore } from "vuex";
import { auth } from "./auth.module";
import { calc } from "./calc.module";
import { socket } from "./socket.module";
import { metrics } from "./metrics.module";
import { getBaseUrl } from "../util/util";

const store = createStore({
  state: {
    user: null,
    baseAPIUrl: getBaseUrl(), //"http://192.168.0.111:3000/",
    referrer: null,
    links: {},
    colors: {
      blue: "#4F46E5",
      bg: "#EEE",
    },
  },
  modules: {
    auth,
    calc,
    socket,
    metrics,
  },
  mutations: {
    root_logout(state) {
      state.referrer = null;
      state.colors = {
        blue: "#4F46E5",
        bg: "#EEE",
      };
    },
    clear_calc(state) {
      calc.state.calculating = false;
      calc.state.calc_result = null;
      calc.state.ready = false;
    },
  },
});

export default store;
